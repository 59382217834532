window.addEventListener('turbo:load', init)

function init () {
  const el = document.querySelector('.js-sidebar-toggle')
  if (el) { el.addEventListener('click', toggle) }
}

function toggle ({ currentTarget }) {
  currentTarget.classList.toggle('hide')

  const sidebar = document.querySelector('.js-sidebar')
  sidebar.classList.toggle('active')
}
