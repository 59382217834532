import { DataSet as VisDataSet } from 'vis-data'
import { Network as VisNetwork } from 'vis-network'

export class Network {
  constructor (el, path, options) {
    this.el = el
    this.path = path
    this.options = options
  }

  destroy () {
    if (this.network) {
      this.network.destroy()
      this.destroyed = true
    }
  }

  on (eventName, listener) {
    if (!this.listeners) {
      this.listeners = new Map()
    }
    this.listeners.set(eventName, listener)
  }

  async load () {
    const response = await fetch(this.path)
    this.raw = await response.json()
    this.startRender()
  }

  startRender () {
    if (this.destroyed) { return }

    const data = { nodes: this.nodes, edges: this.edges }
    this.vis = new VisNetwork(this.el, data, this.options)
    this.watch('afterDrawing')
    this.watch('zoom')
  }

  watch (eventName) {
    this.vis.on(eventName, (event) => {
      const l = this.listeners && this.listeners.get(eventName)
      if (l) {
        l(event)
      }
    })
  }

  get nodes () {
    return new VisDataSet(this.raw.nodes)
  }

  get edges () {
    return new VisDataSet(this.raw.edges)
  }
}
