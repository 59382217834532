import { Controller } from '@hotwired/stimulus'
import { Network } from '../libs/network'

export default class extends Controller {
  static targets = ['vis', 'scale', 'spinner']
  static values = {
    path: String,
    options: Object,
    scale: Number
  }

  connect () {
    this._network = new Network(this.visTarget, this.pathValue, this.optionsValue)
    this._network.on('afterDrawing', this.handleAfterDrawing)
    this._network.load()
  }

  disconnect () {
    this._network.destroy()
  }

  handleAfterDrawing = () => {
    this.spinnerTarget.classList.add('d-none')
    if (!this.firstAfterDrawing) {
      this.firstAfterDrawing = true
      this.defaultViewPosition = this.network.getViewPosition()
      this.defaultScale = this.scaleValue = this.network.getScale()
    }
  }

  handleZoom = () => {
    this.scaleValue = this.network.getScale()
  }

  toDefaultViewport () {
    this.network.moveTo({
      position: this.defaultViewPosition,
      scale: this.defaultScale
    })
    this.scaleValue = this.defaultScale
  }

  scaleUp () {
    this.zoom(0.25)
  }

  scaleDown () {
    this.zoom(-0.25)
  }

  zoom (scale) {
    this.scaleValue = Math.max(this.scaleValue + scale, 0.01)
    this.network.moveTo({ scale: this.scaleValue })
  }

  scaleValueChanged () {
    this.scaleTarget.textContent = `${Math.round(this.scaleValue * 100)}%`
  }

  get network () {
    return this._network.vis
  }
}
